import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import {
    RentabilidadMonte,
    RentabilidadMes,
    TablaRentabilidadMonte,
    TablaRentabilidadEquipo,
    TablaRentabilidadMes,
    Monte,
} from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class RentabilidadesDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /**************** RENTABILIDADES VARS *****************/
    /*******************************************************/
    public rentabilidad_monte: BehaviorSubject<RentabilidadMonte> =
        new BehaviorSubject({} as RentabilidadMonte)
    public rentabilidad_mes: BehaviorSubject<
        LoadingValue<TablaRentabilidadMes>
    > = new BehaviorSubject({
        loading: false,
        value: {} as TablaRentabilidadMes,
        error: null,
    } as LoadingValue<TablaRentabilidadMes>)
    tabla_rentabilidad_monte: BehaviorSubject<
        LoadingValue<TablaRentabilidadMonte>
    > = new BehaviorSubject({
        loading: false,
        value: {} as TablaRentabilidadMonte,
        error: null,
    } as LoadingValue<TablaRentabilidadMonte>)

    tabla_rentabilidad_equipo: BehaviorSubject<
        LoadingValue<TablaRentabilidadEquipo>
    > = new BehaviorSubject({
        loading: false,
        value: {} as TablaRentabilidadEquipo,
        error: null,
    } as LoadingValue<TablaRentabilidadEquipo>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //** RENTABILIDADES - GET METHODS **********************************/
    /*********************************************************************/
    /* RENTABILIDAD POR MONTE */
    public async fetchRentabilidadMonte(
        monte: Monte,
    ): Promise<RentabilidadMonte> {
        return firstValueFrom(
            this.http
                .get<RentabilidadMonte>(
                    this.env.API_URL + 'rentabilidad_monte',
                    {
                        params: { codigo_monte: monte.codigo_monte },
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: RentabilidadMonte) => {
                        return response || []
                    }),
                    tap((rentabilidad: RentabilidadMonte) => {
                        this.rentabilidad_monte.next(rentabilidad)
                    }),
                ),
        )
    }

    public async fetchTablaRentabilidadMonte(
        monte: Monte,
    ): Promise<TablaRentabilidadMonte> {
        if (this.tabla_rentabilidad_monte.value.loading)
            return {} as TablaRentabilidadMonte
        this.tabla_rentabilidad_monte.next({
            value: {} as TablaRentabilidadMonte,
            loading: true,
        })
        return firstValueFrom(
            this.http
                .get<TablaRentabilidadMonte>(
                    this.env.API_URL + 'tabla_rentabilidad_monte',
                    {
                        params: { codigo_monte: monte.codigo_monte },
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: TablaRentabilidadMonte) => {
                        return response || []
                    }),
                    tap((rentabilidad: TablaRentabilidadMonte) => {
                        console.log('rentabilidad: ', rentabilidad)
                        this.tabla_rentabilidad_monte.next({
                            value: rentabilidad,
                            loading: false,
                        })
                    }),
                ),
        )
    }

    public async fetchTablaRentabilidadEquipo(
        fecha_inicio: Date,
        fecha_fin: Date,
    ): Promise<TablaRentabilidadEquipo> {
        var formData = new FormData()

        if (this.tabla_rentabilidad_equipo.value.loading)
            return {} as TablaRentabilidadEquipo
        this.tabla_rentabilidad_equipo.next({
            value: {} as TablaRentabilidadEquipo,
            loading: true,
        })
        return firstValueFrom(
            this.http
                .get<TablaRentabilidadEquipo>(
                    this.env.API_URL + 'tabla_rentabilidad_equipo',
                    {
                        params: {
                            start_date: fecha_inicio.toISOString(),
                            end_date: fecha_fin.toISOString(),
                        },
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error: any) => {
                        this.tabla_rentabilidad_equipo.next({
                            value: {} as TablaRentabilidadEquipo,
                            loading: false,
                            error: error,
                        })
                        console.error('Error: ', error)
                        throw error
                    }),
                    map((response: TablaRentabilidadEquipo) => {
                        return response || []
                    }),
                    tap((rentabilidad: TablaRentabilidadEquipo) => {
                        this.tabla_rentabilidad_equipo.next({
                            value: rentabilidad,
                            loading: false,
                        })
                        console.log('rentabilidad: ', rentabilidad)
                    }),
                ),
        )
    }

    /* RENTABILIDAD POR MES */
    public async fetchRentabilidadMes(
        start_date: Date,
        end_date: Date,
    ): Promise<TablaRentabilidadMes> {
        var aux = {
            value: {} as TablaRentabilidadMes,
            loading: true,
        }
        this.rentabilidad_mes.next(aux)
        // Get first day of the month
        start_date = new Date(start_date)
        start_date.setDate(1)

        // Get last day of the month

        end_date = new Date(end_date)
        end_date.setMonth(end_date.getMonth() + 1)
        end_date.setDate(0)

        return firstValueFrom(
            this.http
                .get<TablaRentabilidadMes>(
                    this.env.API_URL + 'tabla_rentabilidad_mes',
                    {
                        params: {
                            start_date: start_date.toISOString(),
                            end_date: end_date.toISOString(),
                        },
                        headers: this.headers,
                    },
                )
                .pipe(
                    map((response: TablaRentabilidadMes) => {
                        console.log('responseeee: ', response)
                        return response || []
                    }),
                    tap((rentabilidad: TablaRentabilidadMes) => {
                        var aux = {
                            value: rentabilidad,
                            loading: false,
                        }
                        this.rentabilidad_mes.next(aux)
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.rentabilidad_mes.next({
                            value: {} as TablaRentabilidadMes,
                            loading: false,
                            error: error,
                        })
                        console.error('Error: ', error)
                        return throwError(() => error)
                    }),
                    last(),
                ),
        )
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
