import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import { OtroDato } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class OtrosDatosDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    public otros_datos: BehaviorSubject<LoadingValue<OtroDato[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<OtroDato[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    public async fetchOtrosDatos(): Promise<OtroDato[]> {
        return firstValueFrom(
            this.http
                .get<OtroDato[]>(this.env.API_URL + 'otros_datos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato[]) => {
                        return response || []
                    }),
                    tap((otros_datos: any) => {
                        var aux = {
                            value: otros_datos.reverse(),
                            loading: false,
                        }
                        //////console.log('Otros datos: ', aux)
                        this.otros_datos.next(aux)
                    }),
                ),
        )
    }

    //OTROS DATOS/////////////////////////////////////////////////////////////
    public async postOtroDato(otroDato: OtroDato): Promise<OtroDato> {
        let date = new Date(otroDato.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        otroDato.fecha = formattedDate
        return firstValueFrom(
            this.http
                .post<OtroDato>(this.env.API_URL + 'otros_datos/', otroDato, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato) => {
                        return response
                    }),
                    tap((otroDato: OtroDato) => {
                        this.fetchOtrosDatos()
                        return otroDato
                    }),
                ),
        )
    }

    public async removeOtroDato(otroDato: OtroDato): Promise<OtroDato> {
        return firstValueFrom(
            this.http
                .delete<OtroDato>(
                    this.env.API_URL + 'otros_datos/' + otroDato.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato) => {
                        this.fetchOtrosDatos()
                        return response
                    }),
                ),
        )
    }

    public async updateOtroDato(otroDato: OtroDato): Promise<OtroDato> {
        let date = new Date(otroDato.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        otroDato.fecha = formattedDate
        return firstValueFrom(
            this.http
                .put<OtroDato>(
                    this.env.API_URL + 'otros_datos/' + otroDato.uuid,
                    otroDato,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato) => {
                        return response
                    }),
                    tap((otroDato: OtroDato) => {
                        this.fetchOtrosDatos()
                        return otroDato
                    }),
                ),
        )
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
