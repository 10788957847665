import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import {
    CapacitorSQLite,
    SQLiteConnection,
    SQLiteDBConnection,
    CapacitorSQLitePlugin,
} from '@capacitor-community/sqlite'
import { IDatabase } from './database.interface'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { filter } from 'rxjs/operators'

/**
 * Service that handles all SQLite database operations.
 * Provides methods for CRUD operations and database management.
 */
@Injectable({
    providedIn: 'root',
})
export class DatabaseService implements IDatabase {
    sqliteConnection!: SQLiteConnection
    sqlitePlugin!: CapacitorSQLitePlugin
    db!: SQLiteDBConnection
    platform!: string
    native: boolean = false
    isService: boolean = false

    private dbReady = new BehaviorSubject<boolean>(false)
    public dbReady$ = this.dbReady.asObservable()

    constructor() {
        this.initializePlugin().catch((error) => {
            console.error('Error during database initialization:', error)
            this.dbReady.next(false)
        })
    }

    /**
     * Initializes the SQLite plugin and database connection.
     * @returns Promise that resolves when initialization is complete.
     * @throws Error if initialization fails.
     */
    async initializePlugin(): Promise<void> {
        try {
            this.sqlitePlugin = CapacitorSQLite
            this.sqliteConnection = new SQLiteConnection(this.sqlitePlugin)
            // Inicializa el WebStore (solo es necesario en entorno web)
            await this.sqliteConnection.initWebStore()

            this.isService = true
            await this.initializeDatabase()
            this.dbReady.next(true)
        } catch (error) {
            console.error('Error initializing database:', error)
            this.dbReady.next(false)
            throw error
        }
    }

    /**
     * Se asegura de que la BD esté lista antes de proceder.
     * Lanza un error si no se inicializó correctamente.
     */
    private async ensureInitialized() {
        await firstValueFrom(this.dbReady$.pipe(filter((ready) => ready)))
        if (!this.db) {
            throw new Error('Database not initialized')
        }
    }

    /**
     * Abre o crea la base de datos principal.
     */
    private async initializeDatabase() {
        this.db = await this.openDatabase(
            'SilvanusStorageDB',
            false,
            'no-encryption',
            1,
            false,
        )
        // Verificar si está abierta
        const isDBOpen = await this.db.isDBOpen()
        if (!isDBOpen) {
            throw new Error('Database failed to open')
        }
    }

    /**
     * Crea la conexión con la base de datos, abriendo si ya existe.
     */
    async openDatabase(
        dbName: string,
        encrypted: boolean,
        mode: string,
        version: number,
        readonly: boolean,
    ): Promise<SQLiteDBConnection> {
        let db: SQLiteDBConnection
        const retCC = (
            await this.sqliteConnection.checkConnectionsConsistency()
        ).result
        const isConn = (
            await this.sqliteConnection.isConnection(dbName, readonly)
        ).result

        if (retCC && isConn) {
            // Recuperar una conexión existente
            db = await this.sqliteConnection.retrieveConnection(
                dbName,
                readonly,
            )
        } else {
            // Crear una conexión nueva
            db = await this.sqliteConnection.createConnection(
                dbName,
                encrypted,
                mode,
                version,
                readonly,
            )
        }
        await db.open()
        return db
    }

    /**
     * Creates a table with the specified name.
     * @param tableName - The name of the table to create.
     * @returns Promise that resolves when the table is created.
     */
    async createTable(tableName: string) {
        await this.ensureInitialized()
        const query = `
            CREATE TABLE IF NOT EXISTS ${tableName} (
                key TEXT PRIMARY KEY NOT NULL,
                data TEXT,
                updated_at INTEGER,
                created_at INTEGER
            );
        `
        await this.db.execute(query)
    }

    /**
     * Inserts or updates a single record in the specified table.
     * @param tableName - The name of the target table.
     * @param data - The data object to insert/update.
     * @param keyName - The name of the primary key field in the data object.
     * @throws Error if the operation fails.
     */
    async insertOrUpdate(tableName: string, data: any, keyName: string) {
        await this.ensureInitialized()
        try {
            const query = `
                INSERT OR REPLACE INTO ${tableName} (key, data, updated_at, created_at)
                VALUES (?, ?, ?, ?);
            `
            const updatedAt = Math.floor(Date.now() / 1000)
            const createdAt = data.created_at || updatedAt
            const values = [
                [data[keyName], JSON.stringify(data), updatedAt, createdAt],
            ]

            await this.db.executeSet([
                {
                    statement: query,
                    values,
                },
            ])

            const dbName = await this.db.getConnectionDBName()
            await this.sqliteConnection.saveToStore(dbName)
        } catch (err) {
            console.error('Error in insertOrUpdate:', err)
            throw err
        }
    }

    /**
     * Inserts or updates multiple records in batches.
     * @param tableName - The name of the target table.
     * @param data - Array of data objects to insert/update.
     * @param keyName - The name of the primary key field in the data objects.
     * @throws Error if the operation fails.
     */
    async insertOrUpdateBatch(
        tableName: string,
        data: any[],
        keyName: string,
    ): Promise<void> {
        await this.ensureInitialized()

        if (!data || data.length === 0) {
            return
        }

        try {
            const query = `
                INSERT OR REPLACE INTO ${tableName} (key, data, updated_at, created_at)
                VALUES (?, ?, ?, ?);
            `
            const updatedAt = Math.floor(Date.now() / 1000)
            const values = data.map((row) => [
                row[keyName],
                JSON.stringify(row),
                updatedAt,
                row.created_at || updatedAt,
            ])

            // Dividir en bloques de 500 registros
            const chunkSize = 500
            for (let i = 0; i < values.length; i += chunkSize) {
                const chunk = values.slice(i, i + chunkSize)
                await this.db.executeSet([
                    {
                        statement: query,
                        values: chunk,
                    },
                ])
            }

            const dbName = await this.db.getConnectionDBName()
            await this.sqliteConnection.saveToStore(dbName)
        } catch (err) {
            console.error('Error in insertOrUpdateBatch:', err)
            throw err
        }
    }

    /**
     * Deletes a row from the specified table by its key.
     * @param tableName - The name of the target table.
     * @param key - The primary key value of the row to delete.
     * @throws Error if the deletion fails.
     */
    async deleteRow(tableName: string, key: string) {
        await this.ensureInitialized()
        const query = `DELETE FROM ${tableName} WHERE key = "${key}";`
        await this.db.execute(query)
        const dbName = await this.db.getConnectionDBName()
        await this.sqliteConnection.saveToStore(dbName)
    }

    /**
     * Retrieves all records from the specified table.
     * @param tableName - The name of the target table.
     * @returns Promise resolving to an array of parsed data objects.
     */
    async selectAll(tableName: string): Promise<any[]> {
        await this.ensureInitialized()
        const query = `SELECT * FROM ${tableName} ORDER BY created_at DESC;`
        const result = await this.db.query(query)
        return (result.values ?? []).map((row) => JSON.parse(row.data))
    }

    /**
     * Retrieves a single record by its key.
     * @param tableName - The name of the target table.
     * @param key - The primary key value to search for.
     * @returns Promise resolving to the parsed data object.
     */
    async selectByKey(tableName: string, key: string) {
        await this.ensureInitialized()
        const query = `SELECT * FROM ${tableName} WHERE key = ? LIMIT 1;`
        const result = await this.db.query(query, [key])
        return (result.values ?? []).map((row) => JSON.parse(row.data))
    }

    /**
     * Gets the timestamp of the most recent update in the table.
     * @param tableName - The name of the target table.
     * @returns Promise resolving to the latest update timestamp or 0 if table is empty.
     */
    async getLastUpdate(tableName: string): Promise<number> {
        await this.ensureInitialized()
        const query = `
            SELECT COALESCE(MAX(updated_at), 0) as lastUpdate
            FROM ${tableName};
        `
        const result = await this.db.query(query)
        return result.values?.[0]?.lastUpdate || 0
    }

    /**
     * Drops the specified table if it exists.
     * @param tableName - The name of the table to drop.
     */
    async dropTable(tableName: string) {
        await this.ensureInitialized()
        await this.db.execute(`DROP TABLE IF EXISTS ${tableName};`)
        const dbName = await this.db.getConnectionDBName()
        await this.sqliteConnection.saveToStore(dbName)
    }

    /**
     * Completely removes the database and all its tables.
     * Warning: This operation is irreversible.
     */
    async dropDatabase() {
        await this.ensureInitialized()

        // Listar todas las tablas y eliminarlas
        const tables = await this.db.getTableList()
        for (const table of tables.values ?? []) {
            await this.dropTable(table)
        }

        // Cerrar la base de datos
        await this.db.close()

        // Borrar físicamente el archivo de la base de datos
        await this.sqlitePlugin.deleteDatabase({
            database: 'SilvanusStorageDB',
        })
    }
}
