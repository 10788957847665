import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    LOCALE_ID,
    DEFAULT_CURRENCY_CODE,
} from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { IonicModule } from '@ionic/angular' // Fixed import
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ServicesModule } from './services/services.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
    HashLocationStrategy,
    LocationStrategy,
    registerLocaleData,
} from '@angular/common'
import localeEs from '@angular/common/locales/es'
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker'
import { IonicSelectableComponent } from 'ionic-selectable'
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos'
import { NgxPaginationModule } from 'ngx-pagination'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BsDatepickerModule.forRoot(),
        IonicModule.forRoot(),
        AppRoutingModule,
        ServicesModule,
        BrowserAnimationsModule,
        IonicSelectableComponent,
        NgxPaginationModule,
        TooltipModule.forRoot(),
    ],
    //providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    //HashLocationStrategy instead of IonicRouteStrategy
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    ],

    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(localeService: BsLocaleService) {
        defineLocale('es', esLocale)
        localeService.use('es')
        registerLocaleData(localeEs)
    }
}
