<ion-header>
    <ion-toolbar>
        <ion-title>Histórico de accesos</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="access-log-container">
        <h1
            style="
                font-size: 80%;
                margin-top: 0px;
                color: var(--ion-color-primary);
                font-weight: bold;
            ">
            REGISTRO DE ACCESOS
        </h1>

        <table>
            <thead>
                <tr>
                    <th style="min-width: 230px"><h3>Fecha</h3></th>
                    <th><h3>Usuario</h3></th>
                    <th><h3>Estado</h3></th>
                    <th><h3>Detalles</h3></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let entry of accessLog">
                    <td style="min-width: 230px">
                        {{ entry.timestamp | date : 'medium' }}
                    </td>
                    <td>{{ entry.username }}</td>
                    <td>
                        <ion-badge
                            [color]="
                                entry.action.includes('exitoso')
                                    ? 'success'
                                    : 'danger'
                            ">
                            {{ entry.action }}
                        </ion-badge>
                    </td>
                    <td>{{ entry.details }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ion-content>
