import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import { Equipo, Cabezal, CosteEquipo } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class EquiposDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR DE RECURSOS VARS *****************/
    /*******************************************************/

    public equipos: BehaviorSubject<LoadingValue<Equipo[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Equipo[]>)
    public cabezales: BehaviorSubject<LoadingValue<Cabezal[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Cabezal[]>)
    public equipos_assigned_to_user: BehaviorSubject<Equipo[]> =
        new BehaviorSubject([] as Equipo[])
    public uniqueActividades: BehaviorSubject<string[]> = new BehaviorSubject(
        [] as string[],
    )

    public coste_equipos: BehaviorSubject<LoadingValue<CosteEquipo[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteEquipo[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //** FETCH METHODS *******************************/
    /*********************************************************************/

    public async fetchEquipos(): Promise<Equipo[]> {
        if (this.equipos.value.loading) return []
        this.equipos.next({ ...this.equipos.value, loading: true })

        return firstValueFrom(
            this.http
                .get<Equipo[]>(this.env.API_URL + 'equipos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo[]) => {
                        return response || []
                    }),
                    tap((equipos: any) => {
                        this.equipos.next({
                            loading: false,
                            value: equipos.equipos.reverse(),
                        })
                        this.equipos_assigned_to_user.next(
                            equipos.equipos_assigned_to_user,
                        )
                    }),
                ),
        )
    }

    public async fetchCabezales(): Promise<Cabezal[]> {
        if (this.cabezales.value.loading) return []
        this.cabezales.next({ ...this.cabezales.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Cabezal[]>(this.env.API_URL + 'cabezales', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cabezal[]) => {
                        return response || []
                    }),
                    tap((cabezales: any) => {
                        var aux = {
                            value: cabezales.reverse(),
                            loading: false,
                        }
                        this.cabezales.next(aux)
                    }),
                ),
        )
    }

    public fetchUniqueActividades(): Promise<string[]> {
        return firstValueFrom(
            this.http
                .get<string[]>(this.env.API_URL + 'cabezales/actividades', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: string[]) => {
                        return response || []
                    }),
                    tap((actividades: string[]) => {
                        this.uniqueActividades.next(actividades)
                        ////////console.log("UNIQUE ACTIVIDADES: ", actividades);
                    }),
                ),
        )
    }

    public async fetchCosteEquipo(equipo: Equipo): Promise<CosteEquipo[]> {
        return firstValueFrom(
            this.http
                .get<CosteEquipo[]>(this.env.API_URL + 'coste_equipo/', {
                    params: { equipo_uuid: equipo.uuid ?? '' },
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteEquipo[]) => {
                        return response || []
                    }),
                    tap((coste_equipos: any) => {
                        var aux = {
                            value: coste_equipos.reverse(),
                            loading: false,
                        }
                        //////console.log('Coste equipo: ', aux)

                        this.coste_equipos.next(aux)
                    }),
                ),
        )
    }

    public async fetchAllCosteEquipo(): Promise<CosteEquipo[]> {
        return firstValueFrom(
            this.http
                .get<CosteEquipo[]>(this.env.API_URL + 'coste_equipos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteEquipo[]) => {
                        return response || []
                    }),
                    tap((coste_equipos: any) => {
                        var aux = {
                            value: coste_equipos.reverse(),
                            loading: false,
                        }

                        this.coste_equipos.next(aux)
                    }),
                ),
        )
    }

    /*********************************************************************/
    /** XESTOR DE RECUROS - UPDATE/POST METHODS **************************/
    /*********************************************************************/

    //HORAS/////////////////////////////////////////////////////////////

    //EQUIPO/////////////////////////////////////////////////////////////
    public updateEquipo(equipo: Equipo): Promise<Equipo> {
        console.log('Equipo a actualizar: ', equipo)
        return firstValueFrom(
            this.http
                .put<Equipo>(
                    this.env.API_URL + 'equipos/' + equipo.uuid,
                    {
                        ...equipo,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                    tap((equipo: Equipo) => {
                        const index = this.equipos.value.value.findIndex(
                            (x) => x.uuid === equipo.uuid,
                        )
                        this.equipos.value.value[index] = equipo
                        this.equipos.next(this.equipos.value)
                        return equipo
                    }),
                ),
        )
    }

    public postEquipo(equipo: Equipo): Promise<Equipo> {
        return firstValueFrom(
            this.http
                .post<Equipo>(this.env.API_URL + 'equipos/', equipo, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                    tap((equipo: Equipo) => {
                        this.fetchEquipos()
                        return equipo
                    }),
                ),
        )
    }

    public postEquipoCabezales(equipo: Equipo, cabezales: Cabezal[]) {
        if (cabezales.length) {
            equipo.cabezales = cabezales
        }
        //////console.log('Equipo a postear: ', equipo)
        return firstValueFrom(
            this.http
                .post<Equipo>(this.env.API_URL + 'equipos', equipo, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        //check if evironment debug = true
                        if (
                            this.env.DEBUG &&
                            error.name == 'HttpErrorResponse'
                        ) {
                            //////////console.log("error: ", error);
                            this.alertService.showHTMLAlert(
                                'Error',
                                error.error.message || error.message,
                            )
                        }
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                    tap((equipo: Equipo) => {
                        this.fetchEquipos()
                        return equipo
                    }),
                ),
        )
    }

    public removeEquipo(equipo: Equipo): Promise<Equipo> {
        return firstValueFrom(
            this.http
                .delete<Equipo>(this.env.API_URL + 'equipos/' + equipo.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                ),
        )
    }

    public postInactivoCabezal(inactivoData: any): Promise<any> {
        return firstValueFrom(
            this.http
                .post<any>(
                    this.env.API_URL + 'cabezales/inactivo',
                    inactivoData,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        //check if evironment debug = true
                        if (
                            this.env.DEBUG &&
                            error.name == 'HttpErrorResponse'
                        ) {
                            //////////console.log("error: ", error);
                            this.alertService.showHTMLAlert(
                                'Error',
                                error.error.message || error.message,
                            )
                        }
                        throw error
                    }),
                    map((response: any) => {
                        return response
                    }),
                    tap((inactivo: any) => {
                        ////////console.log("INACTIVO: ", inactivo);
                    }),
                ),
        )
    }

    public postCabezal(cabezal: Cabezal): Promise<Cabezal> {
        ////////console.log("CABEZAL A POSTEAR: ", cabezal);
        return firstValueFrom(
            this.http
                .post<Cabezal>(this.env.API_URL + 'cabezales/', cabezal, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Cabezal) => {
                        this.fetchEquipos()
                        return response
                    }),
                ),
        )
    }

    public removeCabezal(cabezal: Cabezal): Promise<Cabezal> {
        return firstValueFrom(
            this.http
                .delete<Cabezal>(
                    this.env.API_URL + 'cabezales/' + cabezal.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cabezal) => {
                        return response
                    }),
                ),
        )
    }

    public async postCosteEquipo(
        costeEquipo: CosteEquipo,
    ): Promise<CosteEquipo> {
        //Sanatize costeEquipo.fecha removing hour, minutes and seconds
        let date = new Date(costeEquipo.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        costeEquipo.fecha = formattedDate
        //////console.log('formattedDate: ', formattedDate)
        return firstValueFrom(
            this.http
                .post<CosteEquipo>(
                    this.env.API_URL + 'coste_equipos/',
                    costeEquipo,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)

                        throw error
                    }),
                    map((response: CosteEquipo) => {
                        return response
                    }),
                    tap((costeOperario: CosteEquipo) => {
                        this.fetchCosteEquipo(
                            costeEquipo.equipo ??
                                ({ uuid: costeEquipo.equipo_uuid } as Equipo),
                        )
                        return costeOperario
                    }),
                ),
        )
    }

    public async removeCosteEquipo(
        costeEquipo: CosteEquipo,
    ): Promise<CosteEquipo> {
        return firstValueFrom(
            this.http
                .delete<CosteEquipo>(
                    this.env.API_URL + 'coste_equipos/' + costeEquipo.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteEquipo) => {
                        this.fetchCosteEquipo(
                            costeEquipo.equipo ??
                                ({ uuid: costeEquipo.equipo_uuid } as Equipo),
                        )
                        return response
                    }),
                ),
        )
    }

    public async updateCosteEquipo(
        costeEquipo: CosteEquipo,
    ): Promise<CosteEquipo> {
        /* Format costeEquipo.fecha to MM/YYYY */
        let date = new Date(costeEquipo.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        costeEquipo.fecha = formattedDate
        return firstValueFrom(
            this.http
                .put<CosteEquipo>(
                    this.env.API_URL + 'coste_equipos/' + costeEquipo.uuid,
                    {
                        coste_hora: costeEquipo.coste_hora,
                        fecha: formattedDate,
                        titulo: costeEquipo.titulo,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)

                        //Check if error is 409
                        throw error
                    }),
                    map((response: CosteEquipo) => {
                        return response
                    }),
                    tap((response: CosteEquipo) => {
                        this.fetchCosteEquipo(
                            costeEquipo.equipo ??
                                ({ uuid: costeEquipo.equipo_uuid } as Equipo),
                        )
                        return response
                    }),
                ),
        )
    }

    /*********************************************************************/

    async resetData() {
        this.equipos.next({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Equipo[]>)
        this.cabezales.next({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Cabezal[]>)
        this.equipos_assigned_to_user.next([] as Equipo[])
        this.uniqueActividades.next([] as string[])
        this.coste_equipos.next({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteEquipo[]>)
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
