import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import { CosteMonte, Monte } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class MontesDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR DE RECURSOS VARS *****************/
    /*******************************************************/

    public montes: BehaviorSubject<LoadingValue<Monte[]>> = new BehaviorSubject(
        {
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Monte[]>,
    )

    public montes_assigned_to_user: BehaviorSubject<Monte[]> =
        new BehaviorSubject([] as Monte[])
    public coste_montes: BehaviorSubject<LoadingValue<CosteMonte[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteMonte[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //**FETCH METHODS *******************************/
    /*********************************************************************/
    public async fetchMontes(completed: boolean = true): Promise<Monte[]> {
        if (this.montes.value.loading) return []
        this.montes.next({ ...this.montes.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Monte[]>(
                    this.env.API_URL +
                        'montes' +
                        (completed ? '?completed=true' : ''),
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Monte[]) => {
                        return response || []
                    }),
                    tap((montes: any) => {
                        ////////console.log("MONTES: ", montes);
                        /* Invertir ordena montes.equipos*/
                        this.montes.next({
                            value: montes.montes.reverse(),
                            loading: false,
                        })
                        this.montes_assigned_to_user.next(
                            montes.montes_assigned_to_user,
                        )
                    }),
                ),
        )
    }

    public async fetchCosteMonte(monte: Monte): Promise<CosteMonte[]> {
        return firstValueFrom(
            this.http
                .get<CosteMonte[]>(this.env.API_URL + 'coste_montes/', {
                    params: { monte_uuid: monte.uuid },
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte[]) => {
                        return response || []
                    }),
                    tap((coste_montes: any) => {
                        var aux = {
                            value: coste_montes.reverse(),
                            loading: false,
                        }

                        this.coste_montes.next(aux)
                    }),
                ),
        )
    }

    public async fetchAllCosteMonte(): Promise<CosteMonte[]> {
        return firstValueFrom(
            this.http
                .get<CosteMonte[]>(this.env.API_URL + 'coste_montes', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte[]) => {
                        return response || []
                    }),
                    tap((all_coste_monte: any) => {
                        var aux = {
                            value: all_coste_monte.reverse(),
                            loading: false,
                        }

                        this.coste_montes.next(aux)
                    }),
                ),
        )
    }

    /*********************************************************************/
    /** UPDATE/POST METHODS **************************/
    /*********************************************************************/

    //MONTE/////////////////////////////////////////////////////////////
    public postMonte(monte: Monte): Promise<Monte> {
        return firstValueFrom(
            this.http
                .post<Monte>(this.env.API_URL + 'montes/', monte, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        return response
                    }),
                    tap((monte: Monte) => {
                        this.fetchMontes()
                        return monte
                    }),
                ),
        )
    }

    public removeMonte(monte: Monte): Promise<Monte> {
        return firstValueFrom(
            this.http
                .delete<Monte>(this.env.API_URL + 'montes/' + monte.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        this.fetchMontes()
                        return response
                    }),
                ),
        )
    }

    public updateMonte(monte: Monte): Promise<Monte> {
        return firstValueFrom(
            this.http
                .put<Monte>(
                    this.env.API_URL + 'montes/' + monte.uuid,
                    {
                        completed: monte.completed,
                        coste_propietario: monte.coste_propietario,
                        coste_servicios_externos:
                            monte.coste_servicios_externos,
                        denominacion_casa: monte.denominacion_casa || '',
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        return response
                    }),
                    tap((monte: Monte) => {
                        this.fetchMontes()
                        return monte
                    }),
                ),
        )
    }

    public completeAsignacion(monte: Monte) {
        return firstValueFrom(
            this.http
                .post<Monte>(
                    this.env.API_URL + 'montes/completado',
                    {
                        uuid: monte.uuid,
                        completed: monte.completed,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        return response
                    }),
                    tap((monte: Monte) => {
                        this.fetchMontes() // Fetch updated montes after completing assignment
                        return monte
                    }),
                ),
        )
    }

    //COSTE MONTES/////////////////////////////////////////////////////////////
    public async createCosteMonte(costeMonte: CosteMonte): Promise<CosteMonte> {
        return firstValueFrom(
            this.http
                .post<CosteMonte>(
                    this.env.API_URL + 'coste_montes/',
                    costeMonte,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte) => {
                        return response
                    }),
                    tap((costeMonte: CosteMonte) => {
                        this.fetchCosteMonte(costeMonte.monte as Monte)
                        this.fetchMontes()
                    }),
                ),
        )
    }

    public async postCosteMonte(csv: any): Promise<CosteMonte> {
        var data = new FormData()
        data.append('csv_file', csv)

        return firstValueFrom(
            this.http
                .post<CosteMonte>(
                    this.env.API_URL + 'montes/import_csv',
                    data,
                    { headers: this.headers },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    tap((response: any) => {
                        //console.log('response: ', response)
                        //this.fetchMontes()
                        return response
                    }),
                ),
        )
    }

    public async removeCosteMonte(costeMonte: CosteMonte): Promise<CosteMonte> {
        return firstValueFrom(
            this.http
                .delete<CosteMonte>(
                    this.env.API_URL + 'coste_montes/' + costeMonte.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte) => {
                        this.fetchAllCosteMonte()
                        return response
                    }),
                ),
        )
    }

    public updateCosteMonte(costeMonte: CosteMonte): Promise<CosteMonte> {
        return firstValueFrom(
            this.http
                .put<CosteMonte>(
                    this.env.API_URL + 'coste_montes/' + costeMonte.uuid,
                    costeMonte,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte) => {
                        return response
                    }),
                    tap((costeMonte: CosteMonte) => {
                        this.fetchCosteMonte(costeMonte.monte as Monte)
                    }),
                ),
        )
    }

    /*********************************************************************/

    async resetData() {
        this.montes.next({ loading: false, value: [], error: null })
        this.coste_montes.next({ loading: false, value: [], error: null })
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
