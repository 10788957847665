import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import {
    Asignacion,
    SubcontrataAsignacion,
    Cliente,
} from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class AsignacionesDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR DE RECURSOS VARS *****************/
    /*******************************************************/

    public asignaciones: BehaviorSubject<LoadingValue<Asignacion[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Asignacion[]>)
    public myAsignaciones: BehaviorSubject<LoadingValue<Asignacion[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Asignacion[]>)

    public subcontrataAsignaciones: BehaviorSubject<
        LoadingValue<SubcontrataAsignacion[]>
    > = new BehaviorSubject({
        loading: false,
        value: [],
        error: null,
    } as LoadingValue<SubcontrataAsignacion[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //** FETCH METHODS *******************************/
    /*********************************************************************/
    public async fetchAsignaciones(completed: boolean = false): Promise<{
        asignaciones: Asignacion[]
        my_asignaciones: Asignacion[]
    }> {
        if (
            this.asignaciones.value.loading &&
            this.myAsignaciones.value.loading
        )
            return {
                asignaciones: [],
                my_asignaciones: [],
            }
        this.asignaciones.next({ ...this.asignaciones.value, loading: true })
        this.myAsignaciones.next({
            ...this.myAsignaciones.value,
            loading: true,
        })
        return firstValueFrom(
            this.http
                .get<{
                    asignaciones: Asignacion[]
                    my_asignaciones: Asignacion[]
                }>(
                    this.env.API_URL +
                        'asignaciones' +
                        (completed ? '?completed=true' : ''),
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map(
                        (response: {
                            asignaciones: Asignacion[]
                            my_asignaciones: Asignacion[]
                        }) => {
                            return (
                                response || {
                                    asignaciones: [],
                                    my_asignaciones: [],
                                }
                            )
                        },
                    ),
                    tap(
                        (asignaciones: {
                            asignaciones: Asignacion[]
                            my_asignaciones: Asignacion[]
                        }) => {
                            this.asignaciones.next({
                                value: asignaciones.asignaciones,
                                loading: false,
                            })
                            this.myAsignaciones.next({
                                value: asignaciones.my_asignaciones,
                                loading: false,
                            })
                            ////////console.log("ASIGNACIONES: ", asignaciones);
                        },
                    ),
                ),
        )
    }

    public async fetchSubcontrataAsignaciones(): Promise<
        SubcontrataAsignacion[]
    > {
        return firstValueFrom(
            this.http
                .get<SubcontrataAsignacion[]>(
                    this.env.API_URL + 'subcontrata_asignaciones',
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    tap((asignaciones: SubcontrataAsignacion[]) => {
                        console.log('ASIGNACIONES Subcontrata: ', asignaciones)
                        this.subcontrataAsignaciones.next({
                            value: asignaciones,
                            loading: false,
                        })
                    }),
                ),
        )
    }

    public fetchAsignacionesFromSubcontrata(
        cliente: Cliente,
    ): Promise<SubcontrataAsignacion[]> {
        return firstValueFrom(
            this.http
                .get<SubcontrataAsignacion[]>(
                    this.env.API_URL +
                        'subcontrata_asignaciones/' +
                        cliente.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error: any) => {
                        throw error
                    }),
                    tap((asignaciones: SubcontrataAsignacion[]) => {
                        this.subcontrataAsignaciones.next({
                            value: asignaciones,
                            loading: false,
                        })
                    }),
                ),
        )
    }

    /*********************************************************************/
    /** UPDATE/POST METHODS **************************/
    /*********************************************************************/

    //ASIGNACION/////////////////////////////////////////////////////////////
    public postAsignacion(asignacion: {
        user_uuid: string
        equipo_uuid: string
        monte_uuid: string
    }): Promise<Asignacion> {
        //////////console.log("Asignacion to post: ", asignacion);
        return firstValueFrom(
            this.http
                .post<Asignacion>(
                    this.env.API_URL + 'asignaciones/',
                    {
                        user_uuid: asignacion.user_uuid,
                        equipo_uuid: asignacion.equipo_uuid,
                        monte_uuid: asignacion.monte_uuid,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Asignacion) => {
                        return response
                    }),
                    tap((asignacion: Asignacion) => {
                        this.fetchAsignaciones()
                        return asignacion
                    }),
                ),
        )
    }

    public removeAsignacion(asignacion: Asignacion): Promise<Asignacion> {
        return firstValueFrom(
            this.http
                .delete<Asignacion>(
                    this.env.API_URL + 'asignaciones/' + asignacion.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Asignacion) => {
                        this.fetchAsignaciones()
                        return response
                    }),
                ),
        )
    }

    public postSubcontrataAsignacion(asignacion: SubcontrataAsignacion) {
        return firstValueFrom(
            this.http
                .post<SubcontrataAsignacion>(
                    this.env.API_URL + 'subcontrata_asignaciones/',
                    {
                        cliente_uuid: asignacion.cliente_uuid,
                        monte_uuid: asignacion.monte_uuid,
                        tarifas: asignacion.tarifas,
                        created_at: asignacion.created_at,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: SubcontrataAsignacion) => {
                        return response
                    }),
                    tap((asignacion: SubcontrataAsignacion) => {
                        this.fetchSubcontrataAsignaciones()
                        return asignacion
                    }),
                ),
        )
    }

    public updateSubcontrataAsignacion(asignacion: SubcontrataAsignacion) {
        console.log('Asignacion to update: ', asignacion)
        return firstValueFrom(
            this.http
                .put<SubcontrataAsignacion>(
                    this.env.API_URL +
                        'subcontrata_asignaciones/' +
                        asignacion.uuid,
                    {
                        cliente_uuid: asignacion.cliente_uuid,
                        monte_uuid: asignacion.monte_uuid,
                        tarifas: asignacion.tarifas,
                        created_at: asignacion.created_at,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: SubcontrataAsignacion) => {
                        return response
                    }),
                    tap((asignacion: SubcontrataAsignacion) => {
                        this.fetchSubcontrataAsignaciones()
                        return asignacion
                    }),
                ),
        )
    }

    public completeAsignacion(asignacion: Asignacion) {
        return firstValueFrom(
            this.http
                .post<Asignacion>(
                    this.env.API_URL + 'asignaciones/completado',
                    {
                        uuid: asignacion.uuid,
                        completed_at: asignacion.completed_at,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Asignacion) => {
                        return response
                    }),
                    tap((asignacion: Asignacion) => {
                        //this.fetchAsignaciones()
                        return asignacion
                    }),
                ),
        )
    }

    public completeSubcontrataAsignacion(asignacion: SubcontrataAsignacion) {
        console.log('Asignacion to complete: ', asignacion)
        return firstValueFrom(
            this.http
                .post<SubcontrataAsignacion>(
                    this.env.API_URL + 'subcontrata_asignaciones/completado',
                    asignacion,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: SubcontrataAsignacion) => {
                        return response
                    }),
                    tap((asignacion: SubcontrataAsignacion) => {
                        this.fetchSubcontrataAsignaciones()
                        return asignacion
                    }),
                ),
        )
    }

    public removeSubcontrataAsignacion(
        asignacion: SubcontrataAsignacion,
    ): Promise<SubcontrataAsignacion> {
        return firstValueFrom(
            this.http
                .delete<SubcontrataAsignacion>(
                    this.env.API_URL +
                        'subcontrata_asignaciones/' +
                        asignacion.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: SubcontrataAsignacion) => {
                        return response
                    }),
                ),
        )
    }

    public resetData() {
        this.asignaciones.next({ loading: true, value: [] })
        this.myAsignaciones.next({ loading: true, value: [] })
    }

    public fetchAllData() {
        forkJoin([this.fetchAsignaciones()]).subscribe(() => {
            this.isDataReady.next(true)
        })
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
