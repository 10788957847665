import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import { Horas } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class HorasDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR DE RECURSOS VARS *****************/
    /*******************************************************/
    public horas: BehaviorSubject<LoadingValue<Horas[]>> = new BehaviorSubject({
        loading: false,
        value: [],
        error: null,
    } as LoadingValue<Horas[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //**FETCH METHODS *******************************/
    /*********************************************************************/

    public async fetchHoras(): Promise<Horas[]> {
        // Return early if already loading
        if (this.horas.value.loading) {
            return []
        }

        try {
            // Set loading state
            this.horas.next({ ...this.horas.value, loading: true, error: null })

            // Initialize database
            await this.databaseService.createTable('horas')
            const lastUpdate =
                (await this.databaseService.getLastUpdate('horas')) ?? 0

            // Fetch data from API
            const response = await firstValueFrom(
                this.http
                    .get<Horas[]>(
                        this.env.API_URL + `horas?time=${lastUpdate}`,
                        {
                            headers: this.headers,
                        },
                    )
                    .pipe(
                        catchError(async (error) => {
                            console.error('API fetch error:', error)
                            // Load from local database on API error
                            const localData =
                                await this.databaseService.selectAll('horas')
                            if (!localData?.length) {
                                throw new Error('No local data available')
                            }
                            return localData
                        }),
                    ),
            )

            // Update local database and state
            if (response.length > 0) {
                await this.databaseService.insertOrUpdateBatch(
                    'horas',
                    response,
                    'uuid',
                )
            }

            const localData = await this.databaseService.selectAll('horas')
            this.horas.next({
                loading: false,
                value: localData,
                error: null,
            })

            return localData
        } catch (error) {
            const errorMsg =
                error instanceof Error
                    ? error.message
                    : 'Unknown error occurred'
            console.error('fetchHoras failed:', errorMsg)
            this.horas.next({
                loading: false,
                value: [],
                error: errorMsg,
            })
            return []
        }
    }

    /*********************************************************************/
    /** UPDATE/POST METHODS **************************/
    /*********************************************************************/

    //HORAS/////////////////////////////////////////////////////////////
    public updateHoras(horas: Horas): Promise<Horas> {
        return firstValueFrom(
            this.http
                .put<Horas>(this.env.API_URL + 'horas/' + horas.uuid, horas, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Horas) => {
                        return response
                    }),
                    tap((horas: Horas) => {
                        this.fetchHoras()
                        return horas
                    }),
                ),
        )
    }

    public postHoras(aux: any): Promise<any> {
        const formData = new FormData()
        formData.append('actividad', aux.actividad)
        formData.append('asignacion_uuid', aux.asignacion_uuid)
        formData.append('consumo_medio', aux.consumo_medio)
        formData.append('dia', new Date(aux.dia).toISOString())
        formData.append('horas_inicio', aux.horas_inicio)
        formData.append('horas_fin', aux.horas_fin)
        formData.append('horas_operario', aux.horas_operario)
        formData.append('observaciones', aux.observaciones)
        formData.append('foto', aux.foto)
        //////////console.log("aux: ", aux);
        //////console.log('formData: ', aux)
        return firstValueFrom(
            this.http
                .post<Horas>(this.env.API_URL + 'horas/', formData, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        //check if evironment debug = true
                        if (
                            this.env.DEBUG &&
                            error.name == 'HttpErrorResponse'
                        ) {
                            //////////console.log("error: ", error);
                            this.alertService.showHTMLAlert(
                                'Error',
                                error.error.message || error.message,
                            )
                        }
                        throw error
                    }),
                    map((response: Horas) => {
                        //////////console.log("response: ", response);
                        return response
                    }),
                    tap((horas: Horas) => {
                        this.fetchHoras()
                        return horas
                    }),
                ),
        )
    }

    public removeHoras(horas: Horas): Promise<Horas> {
        return firstValueFrom(
            this.http
                .delete<Horas>(this.env.API_URL + 'horas/' + horas.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error: any) => {
                        //console.error(error)
                        //Si el código de error es 404 y sigue está en la base de datos, se elimina de la base de datos
                        if (error.status === 404) {
                            this.databaseService.deleteRow('horas', horas.uuid)
                            this.horas.next({
                                loading: false,
                                value: this.horas.value.value.filter(
                                    (item) => item.uuid !== horas.uuid,
                                ),
                                error: null,
                            })
                            return throwError(() => error)
                        }
                        return throwError(() => error)
                    }),
                    map((response: Horas) => {
                        this.databaseService.deleteRow('horas', horas.uuid)
                        this.horas.next({
                            loading: false,
                            value: this.horas.value.value.filter(
                                (item) => item.uuid !== horas.uuid,
                            ),
                            error: null,
                        })
                        return response
                    }),
                ),
        )
    }

    /*********************************************************************/

    public resetData() {
        this.horas.next({ loading: true, value: [] })
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
