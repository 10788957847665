import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule, ModalController } from '@ionic/angular'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import * as loginLogsData from './logs.json'

/**
 * Interface representing a login log entry from the JSON file
 */
interface LoginLogEntry {
    timestamp: string
    requestId: string
    ipAddress: string
    email: string
    status: string
    responseTime?: string
    message?: string
}

/**
 * Interface representing a processed access log entry for display
 */
interface AccessLogEntry {
    timestamp: Date
    username: string
    action: string
    details?: string
}

@Component({
    selector: 'app-access-log',
    templateUrl: './access-log.component.html',
    styleUrls: ['./access-log.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
    ],
})
export class AccessLogComponent implements OnInit {
    @Input() accessLog: AccessLogEntry[] = []

    // Add property for login logs
    loginLogs: LoginLogEntry[] = []

    constructor(private modalController: ModalController) {}

    ngOnInit(): void {
        try {
            // Parse the login logs JSON
            this.loginLogs = Array.isArray(loginLogsData)
                ? loginLogsData
                : (loginLogsData as any).default || []

            if (this.loginLogs.length === 0) {
                console.warn(
                    'No login logs found or logs.json could not be properly imported',
                )
            }

            // Convert string timestamps to Date objects for sorting
            this.loginLogs.sort((a, b) => {
                // Safely handle dates
                const dateA = this.parseDate(a.timestamp)
                const dateB = this.parseDate(b.timestamp)

                if (!dateA && !dateB) return 0
                if (!dateA) return 1 // null dates go to the end
                if (!dateB) return -1

                return dateB.getTime() - dateA.getTime()
            })

            // Generate access log entries from login logs
            this.generateAccessLogs()
        } catch (error) {
            console.error('Error initializing access logs:', error)
            this.accessLog = []
        }
    }

    /**
     * Safely parses a date string and returns a Date object or null if invalid
     */
    private parseDate(dateString: string): Date | null {
        try {
            const date = new Date(dateString)
            // Check if date is valid
            return !isNaN(date.getTime()) ? date : null
        } catch (error) {
            console.warn(`Invalid date format: ${dateString}`)
            return null
        }
    }

    /**
     * Creates formatted access log entries from the login logs
     */
    private generateAccessLogs(): void {
        try {
            // Create access log entries from login logs
            this.accessLog = this.loginLogs
                .filter((log) => log && log.timestamp) // Filter out invalid entries
                .map((log) => {
                    // Safely parse date
                    const logDate = this.parseDate(log.timestamp)
                    if (!logDate) {
                        console.warn(
                            `Skipping log with invalid date: ${log.timestamp}`,
                        )
                        return null
                    }

                    let action = ''
                    let details = ''

                    if (log.status === 'success') {
                        action = 'Inicio de sesión exitoso'
                        details = `IP: ${log.ipAddress}, Tiempo de respuesta: ${
                            log.responseTime || 'N/A'
                        }`
                    } else if (log.status === 'failed') {
                        action = 'Intento de inicio de sesión fallido'
                        details = `IP: ${log.ipAddress}, Mensaje: ${
                            log.message || 'Error desconocido'
                        }`
                    } else {
                        action = `Estado: ${log.status || 'Desconocido'}`
                        details = `IP: ${log.ipAddress}`
                    }

                    return {
                        timestamp: logDate,
                        username: log.email || 'Usuario desconocido',
                        action,
                        details,
                    }
                })
                .filter((entry) => entry !== null) as AccessLogEntry[] // Remove null entries
        } catch (error) {
            console.error('Error generating access logs:', error)
            this.accessLog = []
        }
    }

    /**
     * Closes the modal dialog
     */
    closeModal(): void {
        this.modalController.dismiss()
    }
}
