import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import { Movimiento } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class MovimientosDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR FINANCIERO VARS *****************/
    /*******************************************************/

    public movimientos: BehaviorSubject<LoadingValue<Movimiento[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Movimiento[]>)

    public productos: string[] = []
    public especies: string[] = []

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })
                this.fetchProductosEspecies()
                this.isDataReady.next(true)
            }
        })
    }

    public async fetchProductosEspecies() {
        return firstValueFrom(
            this.http
                .get<any>(this.env.API_URL + 'productos_especie', {
                    headers: this.headers,
                })
                .pipe(
                    catchError(async (error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: any) => {
                        console.log('response: ', response)
                        this.productos = response.productos
                        this.especies = response.especies
                        return response
                    }),
                ),
        )
    }

    public async fetchMovimientos(): Promise<Movimiento[]> {
        this.movimientos.next({ ...this.movimientos.value, loading: true })
        //console.log('fetchMovimientos')
        await this.databaseService.createTable('movimientos')
        //console.log('Tabla Creada o Existente')
        const lastUpdate = await this.databaseService.getLastUpdate(
            'movimientos',
        )
        //console.log('Ultima actualización: ', lastUpdate)
        // Incluir 'time' en los parámetros de la petición HTTP
        return firstValueFrom(
            this.http
                .get<Movimiento[]>(
                    this.env.API_URL + `movimientos?time=${lastUpdate}`,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError(async (error) => {
                        console.error(error)
                        // En caso de error, cargar datos de la base de datos local
                        const localData = await this.databaseService.selectAll(
                            'movimientos',
                        )
                        if (localData) {
                            this.movimientos.next({
                                loading: false,
                                value: localData,
                                error: null,
                            })
                            return []
                        } else {
                            throw error
                        }
                    }),
                    map((response: Movimiento[]) => {
                        return response
                    }),
                    tap(async (movimientos: Movimiento[]) => {
                        // Guardar datos en la base de datos local
                        await this.databaseService.insertOrUpdateBatch(
                            'movimientos',
                            movimientos,
                            'uuid',
                        )
                        // Leer movimientos de la base de datos para obtener el valor actualizado
                        const localData = await this.databaseService.selectAll(
                            'movimientos',
                        )

                        this.movimientos.next({
                            loading: false,
                            value: localData,
                            error: null,
                        })
                    }),
                ),
        )
    }

    //MOVIMIENTOS/////////////////////////////////////////////////////////////
    public async postMovimiento(csv: any): Promise<Movimiento> {
        ////console.log('csv: ', csv)
        var data = new FormData()
        data.append('csv_file', csv)

        return firstValueFrom(
            this.http
                .post<Movimiento>(
                    this.env.API_URL + 'movimientos/import_csv',
                    data,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        //////console.log('response: ', response)
                        return response
                    }),
                    tap((movimiento: Movimiento) => {
                        this.fetchMovimientos()
                        return movimiento
                    }),
                ),
        )
    }

    public async removeMovimiento(movimiento: Movimiento): Promise<Movimiento> {
        return firstValueFrom(
            this.http
                .delete<Movimiento>(
                    this.env.API_URL + 'movimientos/' + movimiento.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        this.fetchMovimientos()
                        return response
                    }),
                ),
        )
    }

    public async updateMovimiento(movimiento: Movimiento): Promise<Movimiento> {
        return firstValueFrom(
            this.http
                .put<Movimiento>(
                    this.env.API_URL + 'movimientos/' + movimiento.uuid,
                    movimiento,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        return response
                    }),
                    tap((movimiento: Movimiento) => {
                        this.fetchMovimientos()
                        return movimiento
                    }),
                ),
        )
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }

    public resetData() {
        this.movimientos.next({
            loading: false,
            value: [],
            error: null,
        })
        this.isDataReady.next(false)
    }
}
