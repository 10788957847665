import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin, throwError } from 'rxjs'
import {
    catchError,
    distinctUntilChanged,
    last,
    map,
    tap,
} from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'
import { LoadingValue } from 'src/app/models/interface'
import { Usuario, CosteOperario } from 'src/app/models/models'
import { DatabaseService } from '../database/database.service'

@Injectable({
    providedIn: 'root',
})
export class UsuariosDataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR DE RECURSOS VARS *****************/
    /*******************************************************/

    public usuarios: BehaviorSubject<LoadingValue<Usuario[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Usuario[]>)

    public coste_operarios: BehaviorSubject<LoadingValue<CosteOperario[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteOperario[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
        private databaseService: DatabaseService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //** XESTOS DE RECUROS - FETCH METHODS *******************************/
    /*********************************************************************/

    public async fetchUsuarios(): Promise<Usuario[]> {
        if (this.usuarios.value.loading) return []
        this.usuarios.next({ ...this.usuarios.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Usuario[]>(this.env.API_URL + 'users', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: any) => {
                        return response.users || []
                    }),
                    tap((users: Usuario[]) => {
                        console.log('USUARIOS: ', users)
                        this.usuarios.next({
                            loading: false,
                            value: users.map((x) => {
                                return {
                                    pending:
                                        x.invitation_accepted_at == null &&
                                        x.invitation_created_at != null,
                                    ...x,
                                }
                            }),
                        })
                    }),
                ),
        )
    }

    public async fetchCosteOperario(user: Usuario): Promise<CosteOperario[]> {
        //////console.log('User: ', user)
        return firstValueFrom(
            this.http
                .get<CosteOperario[]>(this.env.API_URL + 'coste_operario/', {
                    params: { user_uuid: user.uuid },
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario[]) => {
                        return response || []
                    }),
                    tap((coste_operarios: any) => {
                        //////console.log('Coste operario: ', coste_operarios)
                        var aux = {
                            value: coste_operarios.reverse(),
                            loading: false,
                        }
                        this.coste_operarios.next(aux)
                    }),
                ),
        )
    }

    public async fetchAllCosteOperario(): Promise<CosteOperario[]> {
        return firstValueFrom(
            this.http
                .get<CosteOperario[]>(this.env.API_URL + 'coste_operarios/', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario[]) => {
                        return response || []
                    }),
                    tap((coste_operarios: any) => {
                        var aux = {
                            value: coste_operarios.reverse(),
                            loading: false,
                        }
                        this.coste_operarios.next(aux)
                    }),
                ),
        )
    }

    //USUARIOS/////////////////////////////////////////////////////////////
    public postUsuario(usuario: Usuario): Promise<Usuario> {
        console.log('POST USER: ', usuario)
        return firstValueFrom(
            this.http
                .post<Usuario>(
                    this.env.API_URL + 'invitation/',
                    {
                        user: {
                            name: usuario.name,
                            email: usuario.email,
                            role: usuario.role,
                        },
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Usuario) => {
                        return response
                    }),
                    tap((usuario: Usuario) => {
                        this.fetchUsuarios()
                        return usuario
                    }),
                ),
        )
    }

    public removeUsuario(usuario: Usuario): Promise<Usuario> {
        return firstValueFrom(
            this.http
                .delete<Usuario>(this.env.API_URL + 'users/' + usuario.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Usuario) => {
                        this.fetchUsuarios()
                        return response
                    }),
                ),
        )
    }

    public updateUsuario(usuario: Usuario): Promise<Usuario> {
        return firstValueFrom(
            this.http
                .put<Usuario>(
                    this.env.API_URL + 'users/' + usuario.uuid,
                    usuario,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Usuario) => {
                        return response
                    }),
                    tap((usuario: Usuario) => {
                        this.fetchUsuarios()
                        return usuario
                    }),
                ),
        )
    }

    //COSTE OPERARIOS/////////////////////////////////////////////////////////////
    public async postCosteOperario(
        costeOperario: CosteOperario,
    ): Promise<CosteOperario> {
        let date = new Date(costeOperario.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        costeOperario.fecha = formattedDate

        //////console.log(costeOperario)
        return firstValueFrom(
            this.http
                .post<CosteOperario>(
                    this.env.API_URL + 'coste_operarios/',
                    costeOperario,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario) => {
                        return response
                    }),
                    tap((c: CosteOperario) => {
                        this.fetchCosteOperario({
                            uuid: costeOperario.user_uuid,
                        } as Usuario)
                        return c
                    }),
                ),
        )
    }

    public async removeCosteOperario(
        costeOperario: CosteOperario,
    ): Promise<CosteOperario> {
        //////console.log('aquii coste operrio: ', costeOperario)
        return firstValueFrom(
            this.http
                .delete<CosteOperario>(
                    this.env.API_URL + 'coste_operarios/' + costeOperario.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario) => {
                        this.fetchCosteOperario({
                            uuid: costeOperario.user_uuid,
                        } as Usuario)
                        return response
                    }),
                ),
        )
    }

    public async updateCosteOperario(
        costeOperario: CosteOperario,
    ): Promise<CosteOperario> {
        /* Format costeEquipo.fecha to MM/YYYY */
        let date = new Date(costeOperario.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        //////console.log('formattedDate: ', formattedDate)
        //////console.log('costeOperario: ', costeOperario)
        return firstValueFrom(
            this.http
                .put<CosteOperario>(
                    this.env.API_URL + 'coste_operarios/' + costeOperario.uuid,
                    {
                        coste_mes: costeOperario.coste_mes,
                        fecha: formattedDate,
                        titulo: costeOperario.titulo,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario) => {
                        return response
                    }),
                    tap((c: CosteOperario) => {
                        this.fetchCosteOperario(
                            costeOperario.user ??
                                ({ uuid: costeOperario.user_uuid } as Usuario),
                        )
                        return costeOperario
                    }),
                ),
        )
    }

    /*********************************************************************/

    async resetData() {
        this.usuarios.next({
            value: [],
            loading: true,
            error: null,
        })

        this.coste_operarios.next({
            value: [],
            loading: true,
            error: null,
        })
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
