import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core'
import { AuthService } from './services/auth/auth.service'
import { App } from '@capacitor/app'
import { IonMenu, MenuController, Platform } from '@ionic/angular'
import { NavigationEnd, Router, RouterEvent } from '@angular/router'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { Subscription, filter } from 'rxjs'
import { UserService } from './services/user/user.service'
import { SelfUser, Usuario } from './models/models'
import { ModalController } from '@ionic/angular'
import { AccessLogComponent } from './components/modals/access-log/access-log.component'
import { ChangelogComponent } from './components/modals/changelog/changelog.component'
import { Capacitor } from '@capacitor/core'
import { UsuariosDataService } from './services/data/usuarios.service'
import { MontesDataService } from './services/data/montes.service'
import { EquiposDataService } from './services/data/equipos.service'
import { MovimientosDataService } from './services/data/movimientos.service'
import { RentabilidadesDataService } from './services/data/rentabilidades.service'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state(
                'collapsed',
                style({ height: '0px', minHeight: '0', display: 'none' }),
            ),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4,0.0,0.2,1)'),
            ),
        ]),
    ],
    standalone: false,
})
export class AppComponent implements OnInit {
    public isPC: boolean = false
    public splitPaneDisabled: boolean = true
    public selectedItem: number = -1
    public appPages: any[] = []
    public appPagesComprador = [
        {
            title: 'Comandas',
            url: '/asignaciones',
            icon: 'git-branch-outline',
        },
        {
            title: 'Histórico de actividades',
            url: '/dashboard',
            icon: 'list-outline',
        },
        {
            title: 'Xestor de recursos',
            url: '/usuarios',
            icon: 'people-outline',
            subPages: [
                { title: 'Montes', url: '/montes', icon: 'leaf-outline' },
                { title: 'Equipos', url: '/equipos', icon: 'hammer-outline' },
                {
                    title: 'Subcontratas',
                    url: '/subcontratas',
                    icon: 'document-outline',
                },
            ],
        },
    ]

    public appPagesOficina = [
        {
            title: 'Comandas',
            url: '/asignaciones',
            icon: 'git-branch-outline',
        },
        {
            title: 'Histórico de actividades',
            url: '/dashboard',
            icon: 'list-outline',
        },
        {
            title: 'Xestor de recursos',
            url: '/usuarios',
            icon: 'people-outline',
            subPages: [
                { title: 'Montes', url: '/montes', icon: 'leaf-outline' },
                { title: 'Equipos', url: '/equipos', icon: 'hammer-outline' },
                {
                    title: 'Subcontratas',
                    url: '/subcontratas',
                    icon: 'document-outline',
                },
            ],
        },
        {
            title: 'Xestor financieiro',
            url: '/clientes',
            icon: 'briefcase-outline',
            subPages: [
                {
                    title: 'Empresas',
                    url: '/clientes',
                    icon: 'business-outline',
                },
                {
                    title: 'Movementos',
                    url: '/movimientos',
                    icon: 'analytics-outline',
                },
                {
                    title: 'Tarifas',
                    url: '/precios-ventas',
                    icon: 'pricetag-outline',
                },

                {
                    title: 'Outros datos',
                    url: '/otros-datos',
                    icon: 'information-circle-outline',
                },
                {
                    title: 'Costes Montes',
                    url: '/coste-montes',
                    icon: 'leaf-outline',
                },
            ],
        },
    ]

    public appPagesAdmin = [
        {
            title: 'Comandas',
            url: '/asignaciones',
            icon: 'git-branch-outline',
        },
        {
            title: 'Histórico de actividades',
            url: '/dashboard',
            icon: 'list-outline',
        },
        {
            title: 'Xestor de recursos',
            url: '/usuarios',
            icon: 'people-outline',
            subPages: [
                {
                    title: 'Traballadores',
                    url: '/usuarios',
                    icon: 'people-outline',
                },
                { title: 'Montes', url: '/montes', icon: 'leaf-outline' },
                { title: 'Equipos', url: '/equipos', icon: 'hammer-outline' },
                {
                    title: 'Subcontratas',
                    url: '/subcontratas',
                    icon: 'document-outline',
                },
            ],
        },
        {
            title: 'Xestor financieiro',
            url: '/clientes',
            icon: 'briefcase-outline',
            subPages: [
                {
                    title: 'Empresas',
                    url: '/clientes',
                    icon: 'business-outline',
                },
                {
                    title: 'Movementos',
                    url: '/movimientos',
                    icon: 'analytics-outline',
                },
                {
                    title: 'Tarifas',
                    url: '/precios-ventas',
                    icon: 'pricetag-outline',
                },
                {
                    title: 'Outros datos',
                    url: '/otros-datos',
                    icon: 'information-circle-outline',
                },
                {
                    title: 'Costes Operarios',
                    url: '/coste-operarios',
                    icon: 'people-outline',
                },
                {
                    title: 'Costes Equipos',
                    url: '/coste-equipos',
                    icon: 'hammer-outline',
                },
                {
                    title: 'Costes Montes',
                    url: '/coste-montes',
                    icon: 'leaf-outline',
                },
            ],
        },
        /*         {
            title: 'Cálculos de rentabilidade',
            url: '/rentabilidad-mes',
            icon: 'bar-chart-outline',
            subPages: [
                {
                    title: 'Rentabilidad por mes',
                    url: '/rentabilidad-mes',
                    icon: 'calendar-outline',
                },
                {
                    title: 'Rentabilidad por monte',
                    url: '/rentabilidad-monte',
                    icon: 'leaf-outline',
                },
                {
                    title: 'Rentabilidad por equipo',
                    url: '/rentabilidad-equipo',
                    icon: 'hammer-outline',
                },
            ],
        }, */
    ]
    public appPagesUser = [
        {
            title: 'Comandas',
            url: '/asignaciones',
            icon: 'git-branch-outline',
        },
        /*{
            title: 'Histórico de actividades',
            url: '/dashboard',
            icon: 'list-outline',
            subPages: [
                {
                    title: 'Páxina de actividades',
                    url: '/dashboard',
                    icon: 'time-outline',
                },
            ],
        },*/
    ]

    public appPagesMovilUser = [
        {
            title: 'Comandas',
            url: '/asignaciones',
            icon: 'git-branch-outline',
        },
        {
            title: 'Histórico de actividades',
            url: '/dashboard',
            icon: 'list-outline',
        },
    ]

    public appVersion = '1.0.0'

    /* Role and user subscription */
    public userRole: string = 'user'
    public user = this.userService.getUser()

    public currentUrl: string = ''

    public native: boolean = false
    public currentPlatform: any
    constructor(
        public authService: AuthService,
        private platform: Platform,
        public menuController: MenuController,
        private router: Router,
        private userService: UserService,
        private modalController: ModalController,
        private usuariosService: UsuariosDataService,
        private montesService: MontesDataService,
        private equiposService: EquiposDataService,
        private movimientosService: MovimientosDataService,
        private rentabilidadesService: RentabilidadesDataService,
    ) {}

    ngOnInit(): void {
        /* Detect size screen */
        this.isPC = this.platform.width() >= 768
        this.platform.resize.subscribe(() => {
            this.isPC = this.platform.width() >= 768
            this.authService.getRole().subscribe((role) => {
                if (!role) {
                    return
                }

                /* Set role */
                this.userRole = role
                console.log(this.userRole)
                /* Set menu */
                if (this.isPC) {
                    if (this.userRole == 'admin') {
                        this.appPages = this.appPagesAdmin
                    } else if (this.userRole == 'oficina') {
                        this.appPages = this.appPagesOficina
                    } else if (this.userRole == 'comprador') {
                        this.appPages = this.appPagesComprador
                    } else {
                        this.appPages = this.appPagesUser
                    }
                } else {
                    this.appPages = this.appPagesMovilUser
                }

                this.selectedItemIndex = this.appPages.findIndex((page) => {
                    return page.url == this.currentUrl
                })

                this.menuController.enable(true)
                this.splitPaneDisabled = false
            })
        })

        this.currentPlatform = Capacitor.getPlatform()
        if (
            this.currentPlatform === 'ios' ||
            this.currentPlatform === 'android'
        )
            this.native = true

        /* Subscription to role */
        this.menuController.enable(false)
        this.splitPaneDisabled = true
        this.menuController.close()

        this.authService.getRole().subscribe((role) => {
            if (!role) {
                return
            }

            /* Set role */
            this.userRole = role

            /* Set menu */
            if (this.isPC) {
                if (this.userRole == 'admin') {
                    this.appPages = this.appPagesAdmin
                } else if (this.userRole == 'oficina') {
                    this.appPages = this.appPagesOficina
                } else {
                    this.appPages = this.appPagesUser
                }
            } else {
                this.appPages = this.appPagesMovilUser
            }

            this.selectedItemIndex = this.appPages.findIndex((page) => {
                return page.url == this.currentUrl
            })

            this.menuController.enable(true)
            this.splitPaneDisabled = false
        })

        /* Get user info */
        this.userService.getUserInfo().subscribe((data) => {
            this.user = data
        })
        //Subscribe to router navigation
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkCurrentItem()
            }
        })
    }

    // When Logout Button is pressed
    logout() {
        this.authService.logout()
        this.menuController.enable(false)
        this.splitPaneDisabled = true
    }

    closeMenu() {
        this.menuController.close()
    }

    closeApp() {
        //If capacitor is native, close the app, else, logout
        if (this.platform.is('capacitor')) {
            App.exitApp()
        } else {
            this.logout()
        }
    }

    selectedItemIndex: number | null = null

    toggleExpand(i: number) {
        if (this.selectedItemIndex == i && this.appPages[i].subPages) {
            this.selectedItemIndex = null
            return
        }
        this.selectedItemIndex = i
        this.router.navigate([this.appPages[i].url])
    }

    isExpandedItem(i: number): boolean {
        return this.selectedItemIndex === i
    }

    checkCurrentItem() {
        this.currentUrl = this.router.url
        //////console.log(this.currentUrl)
        this.selectedItemIndex = this.appPages.findIndex((page) => {
            return page.url == this.currentUrl
        })

        if (this.selectedItemIndex == -1) {
            //Search on subpages
            this.selectedItemIndex = this.appPages.findIndex((page) => {
                if (page.subPages) {
                    return (
                        page.subPages.findIndex((subPage: any) => {
                            //////console.log(subPage.url, this.currentUrl)
                            return subPage.url == this.currentUrl
                        }) != -1
                    )
                }
                return false
            })
        }
    }

    async accessLog() {
        await this.modalController
            .create({
                component: AccessLogComponent,
                cssClass: 'custom-modal',
            })
            .then((modal) => {
                modal.present()
            })
    }

    async changeLog() {
        await this.modalController
            .create({
                component: ChangelogComponent,
                cssClass: 'large-modal',
            })
            .then((modal) => {
                modal.present()
            })
    }
}
